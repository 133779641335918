import React from 'react';

const Copyright = () => {
    return (
        <div>
            <div>
                developed by
            </div>
            <div>
                <a
                    href='https://douglasmofet.com.br/'
                    target='_blank'
                    rel="noopener noreferrer">
                    Douglas Mofet
                </a>
            </div>
        </div>
    )
}

export default Copyright;