import React, { useState, useEffect, useRef, ChangeEvent, FormEvent, CSSProperties } from 'react';
import axios from 'axios';
import { IoIosRibbon, IoIosEye, IoIosEyeOff } from 'react-icons/io';
import Copyright from './Copyright';
import model from './image/model-1080x1080.png';
import AvatarEditor from 'react-avatar-editor'

interface StateType {
  name: string,
  initials: string;
};

interface FormType {
  name: string,
  state: string,
  prefix?: string
}

function App() {
  const [states, setStates] = useState<StateType[]>([]);
  const [picture, setPicture] = useState<string>();
  
  const [pictureEdited, setPictureEdited] = useState<string>();
  
  const [scale, setScale] = useState<number>(1);
  
  const [formData, setFormData] = useState<FormType>({
      name: '',
      state: '',
      prefix: ''
  });

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const editorRef = useRef<AvatarEditor>(null);

  useEffect(() => {
    axios.get<any[]>('./data/states.json')
      .then((response) => {
        const statesList = response.data.map(item => {
          return {
            name: item.nome,
            initials: item.sigla
          } as StateType
        });

        setStates(statesList);
      });
      
      handleCanvas();
  }, []);

  useEffect(() => {
    handleCanvas();
    handleSave();
  }, [pictureEdited, picture, formData, states, model]);

  async function handleCanvas () {
    if (canvasRef.current && model) {

      let ctx = await canvasRef.current.getContext('2d');

      let imgFrame = new Image();
      imgFrame.src = await model;

      if (pictureEdited  && pictureEdited && picture) {
        let imgUser = new Image();
        imgUser.src = pictureEdited;

        imgUser.onload = async () => {
          if (ctx) {
            ctx.drawImage(imgUser, 205, 183, 672, 672);
          }
        }
      }

      imgFrame.onload = async () => {
        if (ctx) {
          ctx && ctx.drawImage(imgFrame, 0, 0);
          ctx.font = "bold 50px GilroyBold";
          ctx.fillStyle = "white";

          ctx.fillText(getTitle(), 390, 915);
          ctx.fillText(formData.state, 390, 965);
          ctx.globalCompositeOperation = 'destination-over';
        }
      }
    }
  }

  const handlePicture = async (event: ChangeEvent<HTMLInputElement>) => {
    let fileList = event.target.files;

    if(fileList && fileList.length > 0) {
      let _file = URL.createObjectURL(fileList[0]);
      setPicture(_file);
    }
  }

  const getTitle = () => {
    let { prefix, name } = formData;
    
    let _prefix = prefix && prefix !== '0' ? `${prefix} ` : ``;
    let title = `${_prefix}${name}`;

    return title;
  }

  const handleForm = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: (value === '0' ? null : value)
    });
  }

  const enableButton = (): boolean => {
    return !(formData.name !== ''
              && formData.name !== null
              && formData.state !== null
              && formData.state !== ''
              && picture !== null
              && picture !== ''
              && pictureEdited !== null
              && pictureEdited !== '');
  }

  const handleGenerateStamp = (event: FormEvent) => {
    event.preventDefault();
    handleSave();
    setTimeout(() => {
      download();  
    }, 750);
  }

  const download = function () {
    if (document) {
      var link = document.createElement('a');
      link.download = `eu_vou_im_in_yo_voy_${getNameFile()}.png`;

      if (canvasRef.current) {
        link.href = canvasRef.current?.toDataURL();
        link.click();
      }
    }
  }

  const getNameFile = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth().toString().length === 1 ? '0' : ''}${date.getMonth() + 1}`;
    const day = `${date.getDate().toString().length === 1 ? '0' : ''}${date.getDate()}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${day}${month}${year}${hours}${minutes}${seconds}`;
  }

  const Canvas = () => {
    // let style = { width: 150, height: 150 } as CSSProperties;
    // let style = { width: 150, height: 150, display: (pictureEdited != null ? '' : 'none') } as CSSProperties;

    return(
        <canvas
          id="canvas"
          ref={canvasRef}
          width={1080}
          height={1080}
          // style={style}
        ></canvas>
    );
  }
  
  async function handleSaveT () {
    setTimeout(() => {
      handleSave();
    }, 500);
  }

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    setScale(parseFloat(e.target.value));
  }

  const handleSave = () => {
    let src = editorRef.current?.getImageScaledToCanvas().toDataURL();
    setPictureEdited(src);
  }

  return (
    <>
      <div className="container">

          <AvatarEditor
            image={picture ? picture : ''}
            ref={editorRef}
            width={1080}
            height={1080}
            border={50}
            borderRadius={1080}
            // color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
            style={{
              maxWidth: 600,
              maxHeight: 600,
            }}
            // onImageChange={() => handleSaveT()}
          />
        
        <br/>
        <br/>

          <input
            style={{ width: '100%' }}
            type="range"
            max="2"
            step="0.01"
            defaultValue="1"
            onChange={handleScale} />

        <br/>
        <br/>

        <form onSubmit={handleGenerateStamp}>
          
          <div style={{ display: 'flex' }}>
            <h2 style={{ marginRight: 15 }}>Crie seu selo de comparecimento ao evento preenchendo os campos abaixo:</h2>
            <Canvas />
          </div>

          <div className='mb-15'>
            <small className='cherry'>* <i>campos obrigatórios</i></small>
          </div>

          <label htmlFor="picture">Selecione uma foto <small className='cherry'>*</small></label>
          <input
            type="file"
            name="picture"
            accept="image/*"
            onChange={handlePicture} />

          <label htmlFor="prefix">Sigla</label>
          <select
            name="prefix"
            onChange={handleForm}>
              <option value="0"></option>
              <option value="Dr.">Dr.</option>
              <option value="Dra.">Dra.</option>
          </select>

          <label htmlFor="name">Nome <small className='cherry'>*</small></label>
          <input
            type="text"
            name="name"
            onChange={handleForm} />
          
          <label htmlFor="name">Estado <small className='cherry'>*</small></label>
          <select
            name="state"
            onChange={handleForm}>
            <option value="0">Selecione seu estado</option>
            {
              states.map((state, index) => {
                return (
                  <option
                    key={index}
                    value={state.name}>
                      {`${state.initials} - ${state.name}`}
                  </option>
                )
              })
            }
          </select>

          <button
            type='submit'
            disabled={enableButton()}>
            <span>Gerar selo</span>
            <IoIosRibbon />
          </button>
        </form>

      </div>

      <Copyright />
    </>
  );
}

export default App;